import pako from 'pako';

export function compressData(data) {
    try {
        const stringifiedData = JSON.stringify(data);

        return pako.deflate(stringifiedData, { to: 'string' });
    } catch (error) {
        return undefined;
    }
}

export function decompressData(compressedData) {
    try {
        const decompressed = pako.inflate(compressedData, { to: 'string' });

        return JSON.parse(decompressed);
    } catch (error) {
        return undefined;
    }
}
