const mainTypes = {
    HIDE_MODAL: 'HIDE_MODAL',
    IS_SHOW_MODAL_DEVICE: 'IS_SHOW_MODAL_DEVICE',
    IS_SHOW_MODAL_CREATE_RULE: 'IS_SHOW_MODAL_CREATE_RULE',
    IS_SHOW_MODAL_CLEAR_MESH_BOT: 'IS_SHOW_MODAL_CLEAR_MESH_BOT',

    GET_ABSTRACT_CAPABILITIES_LIST: 'GET_ABSTRACT_CAPABILITIES_LIST',
    GET_ABSTRACT_CAPABILITIES_LIST_SUCCESS: 'GET_ABSTRACT_CAPABILITIES_LIST_SUCCESS',
    GET_ABSTRACT_CAPABILITIES_LIST_ERROR: 'GET_ABSTRACT_CAPABILITIES_LIST_ERROR',

    GET_ABSTRACTS_LIST: 'GET_ABSTRACTS_LIST',
    GET_ABSTRACTS_LIST_SUCCESS: 'GET_ABSTRACTS_LIST_SUCCESS',
    GET_ABSTRACTS_LIST_ERROR: 'GET_ABSTRACTS_LIST_ERROR',

    GET_INTEGRATIONS_LIST: 'GET_INTEGRATIONS_LIST',
    GET_INTEGRATIONS_LIST_SUCCESS: 'GET_INTEGRATIONS_LIST_SUCCESS',
    GET_INTEGRATIONS_LIST_ERROR: 'GET_INTEGRATIONS_LIST_ERROR',

    ENROLL_TO_PAAS_AUTH: 'ENROLL_TO_PAAS_AUTH',
    ENROLL_TO_PAAS_AUTH_SUCCESS: 'ENROLL_TO_PAAS_AUTH_SUCCESS',
    ENROLL_TO_PAAS_AUTH_ERROR: 'ENROLL_TO_PAAS_AUTH_ERROR',

    SHOW_NAVIGATION: 'SHOW_NAVIGATION',
    HIDE_NAVIGATION: 'HIDE_NAVIGATION',

    SET_STATE_PAGE_BEING_EDITED: 'SET_STATE_PAGE_ BEING_EDITED',
    SAVE_USER_ACTION: 'SAVE_USER_ACTION',
    CLEAR_SAVED_ACTION: 'CLEAR_SAVED_ACTION',
    SET_CONFIRMATION_USER: 'SET_CONFIRMATION_USER',
    CLOUD_ENVIRONMENT: 'cloud_environment',
    INTEGRATIONS_STORAGE: 'integrations_storage',
    SUCCESS_STATUS: 1,
    KEY_INDEX: 0,
    DEFAULT_LIMIT_COUNT: 5,
    DEFAULT_TIMEOUT: 1500,
    MIN_LIMIT_COUNT: 0,
    STEP_DECREASING_LIMIT: 1,
    OEM_IS_LOADING: 'OEM_IS_LOADING',
    OEM_IS_INITIALIZED: 'OEM_IS_INITIALIZED',
    SET_OEM_ID: 'SET_OEM_ID',
};

export default mainTypes;
