import { MENU_ITEMS_IDS } from 'containers/Navigation/constants';
import { EZLOPI_FIRMWARE, UNSUPPORTED, COMPARISON_DATA } from 'constants/MeshbotConstant';

export const EZLOPI_MENU_ITEMS_IDS = {
    [MENU_ITEMS_IDS.ADVANCED_SCRIPTING]: { firmwareVersion: EZLOPI_FIRMWARE.VERSION_3_3_6 },
    [MENU_ITEMS_IDS.VARIABLES]: { firmwareVersion: EZLOPI_FIRMWARE.VERSION_4_1_4 },
    [MENU_ITEMS_IDS.EXPRESSIONS]: { firmwareVersion: EZLOPI_FIRMWARE.VERSION_4_1_4 },
    [MENU_ITEMS_IDS.LUA_SCRIPTS]: { firmwareVersion: EZLOPI_FIRMWARE.VERSION_3_3_6 },
    [MENU_ITEMS_IDS.VIRTUAL_DEVICES]: { firmwareVersion: UNSUPPORTED },
    [MENU_ITEMS_IDS.DEVICE_ASSOCIATIONS]: { firmwareVersion: UNSUPPORTED },
};

export const EZLOPI_COMPARISON_METHODS_VERSIONS = {
    [COMPARISON_DATA.METHOD.COMPARE_NUMBER]: EZLOPI_FIRMWARE.VERSION_3_1_4,
    [COMPARISON_DATA.METHOD.COMPARE_NUMBER_RANGE]: EZLOPI_FIRMWARE.VERSION_3_3_6,
    [COMPARISON_DATA.METHOD.COMPARE_STRINGS]: EZLOPI_FIRMWARE.VERSION_3_3_6,
    [COMPARISON_DATA.METHOD.STRING_OPERATION]: EZLOPI_FIRMWARE.VERSION_3_3_6,
    [COMPARISON_DATA.METHOD.COMPARE_VALUES]: EZLOPI_FIRMWARE.VERSION_3_3_6,
};
