import { Cloud } from 'lib/@mui/Icons';
import {
    ControllerIcon,
    NotificationMeshbot,
    InteractionIcon,
    IcActionWrench,
    IcActionsMeshBot,
} from '../../../assets/icons';
import {
    EZLOGIC_TITLE_AUTOMATION,
    EZLOGIC_TITLE_CLOUD_MESHBOT,
    EZLOGIC_TITLE_INTEGRATION,
    EZLOGIC_TITLE_INTERACTION_FLOW_MESHBOT,
    EZLOGIC_TITLE_LOCAL_MESHBOT,
    EZLOGIC_TITLE_NOTIFICATION,
    EZLOGIC_TITLE_NOTIFICATION_MESHBOT,
    EZLOGIC_TITLE_SYSTEM,
    EZLOGIC_TITLE_SYSTEM_MESHBOT,
    EZLOGIC_TITLE_EZLOPI,
    EZLOGIC_TITLE_EZLOPI_MESHBOT,
} from '../../../constants/language_tokens';
import { SELECTION_COLUMN_ID } from '../../../lib/material-react-table';

/**
 * Rows per page, used in GridTable
 * */
export const ROWS_PER_PAGE = [10, 25, 50];

/**
 * Initial page number, used in MeshBots table
 * */
export const INITIAL_COLUMN_VISIBILITY = { id: false, groupId: false, [SELECTION_COLUMN_ID]: true };
/**
 * MeshBot types
 * */
export const MESHBOT_TYPES = {
    CLOUD: 'cloud',
    CLOUD_NOTIFICATION: 'notification',
    CLOUD_INTERACTION: 'interaction',
    LOCAL: 'local',
    NOTIFICATION_TEMPLATE: 'notificationTemplate',
    NOTIFICATION: 'notification', // TODO, why do we have 2 notifications here?
    EZLOPI: 'ezlopi',
};
export const CLOUD_MESHBOT_TYPES = [
    MESHBOT_TYPES.CLOUD,
    MESHBOT_TYPES.CLOUD_NOTIFICATION,
    MESHBOT_TYPES.CLOUD_INTERACTION,
];

/**
 * Default MeshBot listing page title
 * */
export const MESHBOTS_PAGE_TITLE = 'MeshBots';

/**
 * Default MeshBots sorting model
 */
export const MESHBOT_SORTING_MODEL = {
    field: 'name',
    modelSort: 'asc',
};

/**
 * MeshBot Cloud types
 * */

export const MESHBOT_CLOUD_TYPES = ['cloud', 'notification', 'interaction'];

export const CLOUD_MESHBOT = 'cloudMeshBot';
export const SYSTEM = 'system';
export const NOTIFICATION = 'notification';
export const NOTIFICATION_TEMPLATE_ABSTRACT_UUID = '{abstract.uuid}';

export const INDETERMINATE = 'indeterminate';
export const DUPLICATE_MESHBOT = 'duplicateMeshbot';
/**
 * Configuration object that maps Meshbot types to their respective configuration data.
 *
 * @type {Object}
 */
export const meshBotsTypeCellConfig = {
    [MESHBOT_TYPES.CLOUD]: {
        tableTitleKey: EZLOGIC_TITLE_AUTOMATION,
        hoverTitleKey: EZLOGIC_TITLE_CLOUD_MESHBOT,
        MeshBotTypeIcon: Cloud,
    },
    [MESHBOT_TYPES.CLOUD_NOTIFICATION]: {
        tableTitleKey: EZLOGIC_TITLE_NOTIFICATION,
        hoverTitleKey: EZLOGIC_TITLE_NOTIFICATION_MESHBOT,
        MeshBotTypeIcon: NotificationMeshbot,
    },
    [MESHBOT_TYPES.CLOUD_INTERACTION]: {
        tableTitleKey: EZLOGIC_TITLE_INTEGRATION,
        hoverTitleKey: EZLOGIC_TITLE_INTERACTION_FLOW_MESHBOT,
        MeshBotTypeIcon: InteractionIcon,
    },
    [MESHBOT_TYPES.LOCAL]: {
        tableTitleKey: EZLOGIC_TITLE_AUTOMATION,
        hoverTitleKey: EZLOGIC_TITLE_LOCAL_MESHBOT,
        MeshBotTypeIcon: ControllerIcon,
    },
    [SYSTEM]: {
        tableTitleKey: EZLOGIC_TITLE_SYSTEM,
        hoverTitleKey: EZLOGIC_TITLE_SYSTEM_MESHBOT,
        MeshBotTypeIcon: IcActionWrench,
    },
    [MESHBOT_TYPES.EZLOPI]: {
        tableTitleKey: EZLOGIC_TITLE_EZLOPI,
        hoverTitleKey: EZLOGIC_TITLE_EZLOPI_MESHBOT,
        MeshBotTypeIcon: IcActionsMeshBot,
    },
};

/**
 * Anchor setups of more menu
 * */
export const moreMenuAnchorOrigin = { vertical: 'bottom', horizontal: 'left' };
/**
 * Transform setups of more menu
 * */
export const moreMenuTransformOrigin = { vertical: 'top', horizontal: 'left' };

export const CONTROLLER_MODELS = {
    EZLOPI: 'ezlopi',
    ATOM32: 'ATOM32',
    H2_1: 'h2.1',
    H2_SECURE_1: 'h2_secure.1',
};

export const GET_CONTROLLERS_CONFIG_KEYS = {
    TYPE: 'type',
    IS_ONLINE: 'isOnline',
};
export const BOOLEAN = 'boolean';
export const NOT_GROUP = 'not group';
export const DEFAULT_TABLE_PAGE_INDEX = 0;
export const MESHBOT_TABLE_DEFAULT_PAGE_SIZE = 20;
/**
 * An array of method names used in grouping blocks.
 *
 * @type {['and', 'or', 'xor']}
 */
export const GROUP_BLOCKS_METHOD_NAMES = ['and', 'or', 'xor'];

export const LOCAL_TYPE_CONTROLLERS = [CONTROLLER_MODELS.ATOM32, CONTROLLER_MODELS.H2_1, CONTROLLER_MODELS.H2_SECURE_1];

export const DEVICE_CAPABILITY_MENU_ITEM = 'armed device';
